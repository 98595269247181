.about-container {
    text-align: center;
    height: 100%;
}

.about-container > *:last-child {
    border-bottom: none;
}

.activities {
    display: flex;
    flex-direction: column;
    align-items: center; /* THIS MAKES THE IMG INFO SECTIONS SIT IN THE MIDDLE */
    width: 100%;
}