.home-content {
    display: flex;
    flex-direction: row;
    /* background-image:url("../../../public/images/content/park1.jpg"); */
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-left: none;
    border-right: none;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;
}

.home-text {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: center;
    justify-content: center;
    height: 100%;
    max-width: max-content;
    padding: 20px;
    border-right: 5px solid rgb(var(--SWAN-vlight));
}

.home-image {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 2;
    padding: 0px;
    margin: 0px;
}

.home-image img {
    max-width: 100%;
    height: auto;
    border: 5px solid rgb(var(--SWAN-vlight));
}

@media screen and (max-width: 900px) {
    .home-content {
        flex-direction: column;
    }

    .home-text {
        border: none;
    }
}