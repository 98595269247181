:root {
  height: 100%;
  --SWAN-dark: 107, 0, 203;
  --SWAN-medium: 152, 74, 220;
  --SWAN-light: 183, 126, 229;
  --SWAN-vlight: 194, 150, 235;
  background-color: rgba(var(--SWAN-vlight), .75);
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

header {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 5px solid rgb(var(--SWAN-vlight));
}

main {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

footer {
  display: flex;
  /* flex: none; */
  justify-content: space-around;
  align-items: center;
  align-self: flex-end;
  max-height: 50px;
  width: 100%;
  box-sizing: border-box;
  border-top: 5px solid rgb(var(--SWAN-vlight));
}

.banner {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  background-color: rgba(var(--SWAN-vlight));
}


.banner-item {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  padding-left: 5px;
  padding-right: 5px;
}

.banner-item h1 {
  font-family: "myriad-pro", sans-serif;
  font-weight: 700;
  font-style: normal;
  /* text-transform: uppercase; */
  text-align: center;
}

.banner a {
  display: block;
}

.logo {
  display: block;
  height: 75px;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
}

img#facebook {
  display: block;
  margin: 0px;
  padding: 0px;
  height: 70px;
}

nav {
  display: flex;
  /* flex-direction: row; */
  justify-content: space-evenly;
  align-items: center;
  margin: 0;
  padding: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 60%;
}

nav a {
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  margin-left: 5px;
  margin-right: 5px;
  font-size: 22px;
  font-family: sans-serif;
  text-decoration: none;
  /* text-align: center;  */
  /* color: rgb(var(--SWAN-dark)); */
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .banner-item h1 {
    font-size: 1.5em;
    max-width: 50%;
  }
}